import React from 'react';

import { FeaturedPartnersLarge } from './FeaturedPartnersLarge';
import { FeaturedPartnersSmall } from './FeaturedPartnersSmall';

export const FeaturedPartners = () => (
  <>
    <FeaturedPartnersSmall />
    <FeaturedPartnersLarge />
  </>
);
