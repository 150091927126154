import React, { FC, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
}

const PlusIcon = (props: Props) => (
  <svg
    width={20}
    height={18}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.62144 10.2237H0V7.73684H8.62144V0H11.3786V7.73684H20V10.2237H11.3786V18H8.62144V10.2237Z"
      fill="#5DA294"
    />
  </svg>
);

export { PlusIcon };
