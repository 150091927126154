import { Accordion } from 'component-library/components/accordion/Accordion';
import { Flex } from 'component-library/components/layout/Flex';
import { Heading } from 'component-library/components/typography/Heading';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { device } from 'shared/theme';
import styled from 'styled-components';

const Wrapper = styled(Flex)`
  margin: 120px auto;
  flex-direction: column;
  padding: 16px;

  @media ${device.lg} {
    flex-direction: row;
    max-width: 1200px;
    gap: 96px;
  }
`;

const ImageWrapper = styled(Flex)`
  width: 100%;
  max-height: 550px;

  @media ${device.lg} {
    width: 50%;
  }
`;

const ContentWrapper = styled(Flex).attrs({ column: true })`
  width: 100%;

  @media ${device.lg} {
    width: 50%;
  }
`;

const UnderlinedText = styled.span`
  text-decoration: underline;
`;

export const HelpingRetirees = () => (
  <Wrapper>
    <ImageWrapper>
      <StaticImage src="../images/woman-checking-bank-account-balance.webp" alt="Woman checking bank account balance" />
    </ImageWrapper>
    <ContentWrapper>
      <Heading variant={4}>
          Helping everyday retirees stress <UnderlinedText>less</UnderlinedText>, and live <UnderlinedText>more</UnderlinedText>
      </Heading>
      <Accordion
        rows={[
          { title: 'No fear of money running out', content: 'Our licensed advisors help clients with a holistic picture of their retirement, free from the fear of running out of money.' },
          { title: 'A confident retirement', content: 'Retirable empowers every retiree with solutions to help plan, invest, spend, save and protect themselves in retirement.' },
          { title: 'Knowing what\'s Safe-To-Spend', content: 'Clients receive reliable retirement income while growing their cash savings and emergency funds with our high-yield cash account.' },
          { title: 'A trusted partner', content: 'Every client gains a holistic financial plan for retirement with the ongoing care of a dedicated fiduciary advisor.' },
          { title: '24/7 fraud protection', content: 'Retirable includes $1M in identity theft protection and a suite of identity and credit monitoring tools at no additional charge.' },
        ]}
        marginTop={48}
        marginBottom={48}
      />
    </ContentWrapper>
  </Wrapper>
);
