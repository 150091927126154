import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { ShowOnDesktopAndTablet } from 'component-library/components/wrappers/ShowOn';
import { StaticImage } from 'gatsby-plugin-image';
import { StyledCheckmark } from 'page-components/index/plan/shared';
import React from 'react';
import { device } from 'shared/theme';
import styled from 'styled-components';

const Wrapper = styled(Flex)`
  margin: auto;
  padding: 48px 24px;
  margin-top: 48px;

  @media ${device.lg} {
    max-width: 1200px;
  }
`;

const ContentWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;

`;

const ImageWrapper = styled(Flex)`
  flex-basis: 50%;
`;

const StyledHeading = styled(Heading)`
  max-width: 750px;
`;

const BulletPointsTitle = styled(Body)`
  font-weight: bold;
`;

const BulletPoint = ({ text }: { text: string }) => (
  <Flex alignItems="baseline">
    <StyledCheckmark />
    <Body variant={2}>{text}</Body>
  </Flex>
);

export const BusinessSupport = () => (
  <Wrapper>
    <ContentWrapper>
      <StyledHeading variant={4}>
          Support for your business at every step
      </StyledHeading>
      <Flex column marginTop={24}>
        <BulletPointsTitle variant={2}>A full eco-system including:</BulletPointsTitle>
        <BulletPoint text="Turnkey marketing materials" />
        <BulletPoint text="Co-branded or white label platform" />
        <BulletPoint text="Consistent reporting" />
        <BulletPoint text="Data-driven client insights" />
      </Flex>
    </ContentWrapper>
    <ShowOnDesktopAndTablet>
      <ImageWrapper>
        <StaticImage src="../images/business-support.webp" alt="Business Support" />
      </ImageWrapper>
    </ShowOnDesktopAndTablet>
  </Wrapper>
);
