import { GetStartedButton } from 'component-library/components/buttons/GetStartedButton';
import { Flex } from 'component-library/components/layout/Flex';
import { Heading } from 'component-library/components/typography/Heading';
import React, { FC, ReactNode } from 'react';
import { device } from 'shared/theme';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Body } from 'component-library/components/typography/Body';
import { colors } from 'component-library/styles/colors';
import { navigateToPartnersSignUpForm } from '../partners-sign-up-form';
import { ShowOnDesktopAndTablet, ShowOnMobile } from 'component-library/components/wrappers/ShowOn';

const OuterWrapper = styled(Flex)`
  background-color: ${colors.navyBlue};
  padding: 24px;
`;

const InnerWrapper = styled(Flex)`
  padding: 32px 0;
  margin: auto;

  @media ${device.lg} {
    max-width: 1200px;
  }
`;

const CtaWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;

  @media ${device.lg} {
    flex-direction: row;
  }
`;

const StyledHeading = styled(Heading)`
  max-width: 750px;
`;

const ColumnWrapper = styled(Flex).attrs({ column: true })`
  width: 30%;
`;

interface PlatformFeatureProps {
  title: string;
  content: string;
  icon: ReactNode;
}

const PlatformFeature:FC<PlatformFeatureProps> = ({ title, content, icon }) => (
  <Flex column>
    <Flex gap={8}>
      {icon}
      <Body variant={2} color={colors.white}>{title}</Body>
    </Flex>
    <Body variant={3} color={colors.white}>{content}</Body>
  </Flex>
);

export const Platform = () => (
  <OuterWrapper>
    <InnerWrapper column>
      <CtaWrapper>
        <StyledHeading variant={4} color={colors.white}>
            Our Platform is purpose-built to help retirees.
        </StyledHeading>
        <Flex alignItems="center" justifyContent="space-between">
          <GetStartedButton
            small
            buttonText="Book your demo"
            onClick={navigateToPartnersSignUpForm}
          />
        </Flex>
      </CtaWrapper>
      <ShowOnDesktopAndTablet>
        <Flex marginTop={48} justifyContent="space-between">
          <ColumnWrapper gap={32}>
            <PlatformFeature
              title="Plan"
              content="Personalized retirement investment and income plan designed to maintain lifestyle"
              icon={<StaticImage src="../images/platform-plan.webp" alt="Plan" height={32} />}
            />
            <PlatformFeature
              title="Invest"
              content="Expert investment management built for cash flow, stability and growth"
              icon={<StaticImage src="../images/platform-invest.webp" alt="Invest" height={32} />}
            />
            <PlatformFeature
              title="Advice"
              content="Fiduciary advisors provide guidance to help grow savings and spend smarter"
              icon={<StaticImage src="../images/platform-advice.webp" alt="Advice" height={32} />}
            />
          </ColumnWrapper>
          <ColumnWrapper>
            <StaticImage src="../images/platform-app.webp" alt="Our platform" />
          </ColumnWrapper>
          <ColumnWrapper gap={32}>
            <PlatformFeature
              title="Spend"
              content="Reliable retirement income with one easy, safe-to-spend paycheck"
              icon={<StaticImage src="../images/platform-spend.webp" alt="Spend" height={32} />}
            />
            <PlatformFeature
              title="Save"
              content="Earn interest on cash savings and emergency fund with our high-yield cash account"
              icon={<StaticImage src="../images/platform-save.webp" alt="Save" height={32} />}
            />
            <PlatformFeature
              title="Protect"
              content="$1M in identity theft insurance with a suite of identity and credit monitoring tools"
              icon={<StaticImage src="../images/platform-protect.webp" alt="Protect" height={32} />}
            />
          </ColumnWrapper>
        </Flex>
      </ShowOnDesktopAndTablet>
      <ShowOnMobile>
        <Flex column gap={32} marginTop={48}>
          <PlatformFeature
            title="Plan"
            content="Personalized retirement investment and income plan designed to maintain lifestyle"
            icon={<StaticImage src="../images/platform-plan.webp" alt="Plan" height={32} />}
          />
          <PlatformFeature
            title="Invest"
            content="Expert investment management built for cash flow, stability and growth"
            icon={<StaticImage src="../images/platform-invest.webp" alt="Invest" height={32} />}
          />
          <PlatformFeature
            title="Advice"
            content="Fiduciary advisors provide guidance to help grow savings and spend smarter"
            icon={<StaticImage src="../images/platform-advice.webp" alt="Advice" height={32} />}
          />
          <PlatformFeature
            title="Spend"
            content="Reliable retirement income with one easy, safe-to-spend paycheck"
            icon={<StaticImage src="../images/platform-spend.webp" alt="Spend" height={32} />}
          />
          <PlatformFeature
            title="Save"
            content="Earn interest on cash savings and emergency fund with our high-yield cash account"
            icon={<StaticImage src="../images/platform-save.webp" alt="Save" height={32} />}
          />
          <PlatformFeature
            title="Protect"
            content="$1M in identity theft insurance with a suite of identity and credit monitoring tools"
            icon={<StaticImage src="../images/platform-protect.webp" alt="Protect" height={32} />}
          />
        </Flex>
      </ShowOnMobile>
    </InnerWrapper>
  </OuterWrapper>
);
