import React, { FC } from 'react';

import styled from 'styled-components';
import { Flex } from 'component-library/components/layout/Flex';
import { colors } from 'component-library/styles/colors';
import { Heading } from 'component-library/components/typography/Heading';
import { VideoSmall } from './VideoSmall';
import { VideoLarge } from './VideoLarge';
import { device } from 'shared/theme';
import { ShowOnDesktopAndTablet, ShowOnMobile } from 'component-library/components/wrappers/ShowOn';
import { HubspotForm } from './HubspotForm';

const OuterWrapper = styled.div`
  padding-top: 80px;
  margin-top: -80px;
  margin-bottom: 120px;
`;

const CardWrapper = styled(Flex)`
  background-color: ${colors.peach};
  border-radius: 32px;
  overflow: hidden;
  position: relative;
  margin: 40px auto 0 auto;

  @media ${device.lg} {
    max-width: 1200px;
    border-radius: 32px;
  }
`;

const InnerWrapper = styled(Flex)`
  padding: 64px 20px 0;

  @media ${device.lg} {
    max-width: 1000px;
    margin: 0 auto;
    padding: 60px 40px 120px;
    position: relative;
    z-index: 2;
  }
`;

const StyledHeading = styled(Heading)`
  text-align: center;
`;

const PARTNERS_SIGN_UP_FORM_CONTAINER_ID = 'partnersSignUpForm';

export const navigateToPartnersSignUpForm = () => {
  const partnersSignUpForm = document.getElementById(PARTNERS_SIGN_UP_FORM_CONTAINER_ID);

  if (partnersSignUpForm) {
    partnersSignUpForm.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

export interface PartnersSignUpFormProps {}

export const PartnersSignUpForm: FC<PartnersSignUpFormProps> = () => (
  <OuterWrapper id={PARTNERS_SIGN_UP_FORM_CONTAINER_ID}>
    <CardWrapper column>
      <InnerWrapper column alignItems="center">
        <StyledHeading variant={4} marginBottom={16}>
          Unlock new growth opportunities with Retirable
        </StyledHeading>
        <StyledHeading variant={5} marginBottom={16} marginTop={24}>
          Schedule a demo with us!
        </StyledHeading>
        <HubspotForm />
      </InnerWrapper>
      <ShowOnMobile>
        <VideoSmall />
      </ShowOnMobile>
      <ShowOnDesktopAndTablet>
        <VideoLarge />
      </ShowOnDesktopAndTablet>
    </CardWrapper>
  </OuterWrapper>
);
