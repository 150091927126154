import video from 'page-components/shared/videos/bottom-cta.mp4';
import React from 'react';
import styled from 'styled-components';

import { BottomCtaPattern } from 'page-components/shared/images/BottomCtaPattern';
import { BottomCtaPattern2 } from 'page-components/shared/images/BottomCtaPattern2';
import { BottomCtaPattern3 } from 'page-components/shared/images/BottomCtaPattern3';

const StyledBottomCtaPattern = styled(BottomCtaPattern)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 27.9%;
  height: 118%;
`;

const StyledBottomCtaPattern2 = styled(BottomCtaPattern2)`
  position: absolute;
  bottom: 100%;
  left: 4.8%;
  width: 9.2%;
  height: 58%;
`;

const StyledBottomCtaPattern3 = styled(BottomCtaPattern3)`
  position: absolute;
  left: 18.8%;
  bottom: 0;
  width: 13.9%;
  height: 59%;
`;

const StyledVideo = styled.video`
  border-radius: 0 60px 0 0;
  width: 28%;
`;

const VideoWrapper = styled.div`
  position: absolute;
  width: 105%;
  bottom: 0;
  left: -5%;
`;

export const VideoLarge = () => (
  <VideoWrapper>
    <StyledVideo autoPlay loop muted playsInline>
      <source src={video} type="video/mp4" />
    </StyledVideo>
    <StyledBottomCtaPattern />
    <StyledBottomCtaPattern2 />
    <StyledBottomCtaPattern3 />
  </VideoWrapper>
);
