import { GridColumn } from 'common/layouts/grid/GridColumn';
import { ButtonColor, PrimaryButton } from 'component-library/components/buttons/PrimaryButton';
import { Body } from 'component-library/components/typography/Body';
import { Subheading } from 'component-library/components/typography/Subheading';
import React, { ReactNode, FC } from 'react';
import styled from 'styled-components';

interface IndustryDetailsProps {
  name: string;
  body: string;
  image: ReactNode;
  onLearnMoreClick: () => void;
}

const Wrapper = styled(GridColumn).attrs({
  span: 1,
})`
  padding: 24px;
`;

const IndustryName = styled(Subheading).attrs({
  variant: 1
})`
  font-weight: bold;
  margin: 24px 0;
`;

export const IndustryDetails: FC<IndustryDetailsProps> = ({ name, body, image, onLearnMoreClick }) => (
  <Wrapper>
    <div>
      {image}
    </div>
    <IndustryName>{name}</IndustryName>
    <Body variant={1}>{body}</Body>
    <PrimaryButton
      small
      buttonColor={ButtonColor.Transparent}
      marginTop={24}
      onClick={onLearnMoreClick}
    >
      Learn More
    </PrimaryButton>
  </Wrapper>
);