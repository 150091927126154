import {
  colors,
  colorStyle,
  ColorStyleProps,
} from 'component-library/styles/colors';
import { SpacingProps, spacingStyles } from 'component-library/styles/spacing';
import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const Text = styled.p`
  display: block;
  font-family: 'Manrope';
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;
  margin-bottom: 2px;

  ${colorStyle}
`;

const Underline = styled.div`
  height: 1px;
  border-bottom: 1px solid ${colors.navyBlue};
`;

const TextButtonWrapper = styled.div`
  width: fit-content;

  &:hover {
    ${Underline} {
      border-bottom: 1px solid transparent;
    }
  }

  ${spacingStyles};
`;

export interface TextButtonProps extends ColorStyleProps, SpacingProps {}

export const TextButton: FC<PropsWithChildren<TextButtonProps>> = ({
  children,
  color,
  ...rest
}) => (
  <TextButtonWrapper {...rest}>
    <Text color={color}>{children}</Text>
    <Underline />
  </TextButtonWrapper>
);
