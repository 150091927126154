import { TrustPilot } from 'common/trustpilot/TrustPilot';
import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { ShowOnDesktop, ShowOnMobileAndTablet } from 'component-library/components/wrappers/ShowOn';
import React from 'react';
import { device } from 'shared/theme';
import styled from 'styled-components';

const Wrapper = styled(Flex)`
  flex-direction: column;
  margin: auto;
  gap: 16px;
  padding: 16px;

  @media ${device.lg} {
    flex-direction: row;
    max-width: 1200px;
    justify-content: space-evenly;
  }
`;

const ContentWrapper = styled(Flex).attrs({ column: true })`
  width: 100%;
  padding-top: 84px;
  gap: 48px;

  @media ${device.lg} {
    width: 40%;
  }
`;

const TrustPilotWrapperDesktop = styled(ShowOnDesktop)`
  width: 60%;
`;

const TrustPilotWrapperMobile = styled(ShowOnMobileAndTablet)`
  width: 100%;
`;

const StyledHeading = styled(Heading)`
  max-width: 750px;
`;

export const PartnersTrustPilot = () => (
  <Wrapper>
    <ContentWrapper>
      <StyledHeading variant={4}>
          We&apos;ve earned the trust of 50,000+ Americans
      </StyledHeading>
      <Body variant={2}>
        Don&apos;t just take it from us. Discover why our clients choose us to instill confidence in their retirement.
      </Body>
    </ContentWrapper>
    <TrustPilotWrapperDesktop>
      <TrustPilot
        template="grid"
        height="500px"
        width="700px"
      />
    </TrustPilotWrapperDesktop>
    <TrustPilotWrapperMobile>
      <TrustPilot
        template="grid"
        height="500px"
        width="100%"
      />
    </TrustPilotWrapperMobile>
  </Wrapper>
);
