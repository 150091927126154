import Layout from 'common/layouts/Layout';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { SEO } from 'components/SEO';
import { BusinessSupport } from 'page-components/partners/business-support';
import { FeaturedPartners } from 'page-components/partners/featured-partners';
import { HelpingRetirees } from 'page-components/partners/helping-retirees';
import { PartnersHero } from 'page-components/partners/hero/partners';
import { Industries } from 'page-components/partners/industries';
import { PartnersSignUpForm, navigateToPartnersSignUpForm } from 'page-components/partners/partners-sign-up-form';
import { PartnersTrustPilot } from 'page-components/partners/partners-trust-pilot';
import { Platform } from 'page-components/partners/platform';
import { Featured, Footer } from 'page-components/shared';
import { FooterTemplate } from 'page-components/shared/footer';
import React, { FC } from 'react';

interface PartnersProps {}

const Partners: FC<PartnersProps> = () => (
    <Layout>
        <Navigation onPrimaryCtaClick={navigateToPartnersSignUpForm} />
        <PageWrapper>
          <PartnersHero />
          <Featured />
          <Industries />
          <FeaturedPartners />
          <HelpingRetirees />
          <PartnersTrustPilot />
          <Platform />
          <BusinessSupport />
          <PartnersSignUpForm />
          <Footer template={FooterTemplate.Full} />
        </PageWrapper>
    </Layout>
);

export default Partners;

export const Head = () => (
  <SEO
    title="Partner with Retirable"
    description="Grow your business while helping your customers enjoy a worry-free retirement by partnering with Retirable."
  />
);

