import { Flex } from 'component-library/components/layout/Flex';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

const FeaturedPartnersWrapper = styled(ShowOn)`
  border-top: 1px solid ${colors.navyBlue};
  border-bottom: 1px solid ${colors.navyBlue};
`;

const TrustedBy = styled(Heading)`
  padding: 82px 48px;
  border-right: 1px solid ${colors.navyBlue};
`;

const Logos = styled(Flex)`
  padding: 56px 6%;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const FeaturedPartnersLarge = () => (
  <FeaturedPartnersWrapper screens={[Screen.Xl, Screen.Xxl]}>
    <Flex alignItems="center">
      <TrustedBy variant={5}>Trusted By</TrustedBy>
      <Logos justifyContent="space-between" alignItems="center">
        <StaticImage src="../images/ehealth-logo.webp" alt="eHealth" height={42} />
        <StaticImage src="../images/selectquote-logo.webp" alt="SelectQuote" height={56} />
        <StaticImage src="../images/spark-logo.webp" alt="Spark" height={56} />
        <StaticImage src="../images/the-brokerage-inc-logo.webp" alt="The Brokerage Inc" height={80} />
      </Logos>
    </Flex>
  </FeaturedPartnersWrapper>
);
