import React, { FC, useEffect } from 'react';
import { device } from 'shared/theme';
import styled from 'styled-components';

export const HUBSPOT_FORM = {
  html_element_id: 'hubspotForm',
  portal_id: '23630958',
  form_id: '40a3eb87-fdf3-430b-b61c-ece62d3daf48',
  region: 'na1',
};
const HUBSPOT_SDK_URL = 'https://js.hsforms.net/forms/v2.js';

const FormWrapper = styled.div`
  width: 100%;

  @media ${device.lg} {
    width: 500px;
  }
`;

interface HubspotFormProps {}

export const HubspotForm: FC<HubspotFormProps> = () => {
  useEffect(() => {
    const scriptElement = document.createElement('script');

    scriptElement.src = HUBSPOT_SDK_URL;
    document.body.appendChild(scriptElement);

    scriptElement.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: HUBSPOT_FORM.region,
          portalId: HUBSPOT_FORM.portal_id,
          formId: HUBSPOT_FORM.form_id,
          target: `#${HUBSPOT_FORM.html_element_id}`
        });
      }
    });
  }, []);

  return (
    <FormWrapper>
      <div id={HUBSPOT_FORM.html_element_id} />
    </FormWrapper>
  )
};
