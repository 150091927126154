import { Grid } from 'common/layouts/grid/Grid';
import { Flex } from 'component-library/components/layout/Flex';
import { Heading } from 'component-library/components/typography/Heading';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { device } from 'shared/theme';
import styled from 'styled-components';
import { FINANCIAL_ADVISORS_INDUSTRY, HEALTH_INDUSTRY, HR_INDUSTRY, INSURANCE_INDUSTRY, RETIREMENT_INDUSTRY } from './constants';
import { IndustryDetails } from './Details';
import { IndustryModal } from './Modal';

const Wrapper = styled(Flex).attrs({
  column: true
})`
  margin: auto;
  padding: 120px 40px;

  @media ${device.lg} {
    max-width: 1600px;
  }
`;

const StyledHeading = styled(Heading)`
  max-width: 900px;
  font-size: 56px;
  margin-bottom: 48px;
`;

const IndustryCategories = styled(Grid).attrs({
  cols: 1,
  tabletCols: 2,
  desktopCols: 3
})``;

export const Industries = () => {
  const [selectedIndustry, setSelectedIndustry] = React.useState<string|null>(null);

  const handleLearnMoreClick = (industryId: string) => {
    setSelectedIndustry(industryId);
  }

  const handleCloseClick = () => {
    setSelectedIndustry(null);
  };

  return (
    <Wrapper>
      <StyledHeading variant={3}>
          Designed to support growth across a range of industries
      </StyledHeading>
      <IndustryCategories>
        <IndustryDetails
          name={HEALTH_INDUSTRY.name}
          body={HEALTH_INDUSTRY.body}
          image={<StaticImage src="../images/health-industry.webp" alt={HEALTH_INDUSTRY.name} width={66} />}
          onLearnMoreClick={() => handleLearnMoreClick(HEALTH_INDUSTRY.modal.id)}
        />
        { selectedIndustry === HEALTH_INDUSTRY.modal.id && (
          <IndustryModal
            modalId={HEALTH_INDUSTRY.modal.id}
            heading={HEALTH_INDUSTRY.name}
            body={HEALTH_INDUSTRY.body}
            image={<StaticImage src="../images/health-industry.webp" alt={HEALTH_INDUSTRY.name} width={66} />}
            valueProps={HEALTH_INDUSTRY.modal.valueProps}
            onClose={handleCloseClick}
          />
        )}
        <IndustryDetails
          name={INSURANCE_INDUSTRY.name}
          body={INSURANCE_INDUSTRY.body}
          image={<StaticImage src="../images/insurance-industry.webp" alt={INSURANCE_INDUSTRY.name} width={66} />}
          onLearnMoreClick={() => handleLearnMoreClick(INSURANCE_INDUSTRY.modal.id)}
        />
        { selectedIndustry === INSURANCE_INDUSTRY.modal.id && (
          <IndustryModal
            modalId={INSURANCE_INDUSTRY.modal.id}
            heading={INSURANCE_INDUSTRY.name}
            body={INSURANCE_INDUSTRY.body}
            image={<StaticImage src="../images/insurance-industry.webp" alt={INSURANCE_INDUSTRY.name} width={66} />}
            valueProps={INSURANCE_INDUSTRY.modal.valueProps}
            onClose={handleCloseClick}
          />
        )}
        <IndustryDetails
          name={HR_INDUSTRY.name}
          body={HR_INDUSTRY.body}
          image={<StaticImage src="../images/hr-industry.webp" alt={HR_INDUSTRY.name} width={66} />}
          onLearnMoreClick={() => handleLearnMoreClick(HR_INDUSTRY.modal.id)}
        />
        { selectedIndustry === HR_INDUSTRY.modal.id && (
          <IndustryModal
            modalId={HR_INDUSTRY.modal.id}
            heading={HR_INDUSTRY.name}
            body={HR_INDUSTRY.body}
            image={<StaticImage src="../images/hr-industry.webp" alt={HR_INDUSTRY.name} width={66} />}
            valueProps={HR_INDUSTRY.modal.valueProps}
            onClose={handleCloseClick}
          />
        )}
        <IndustryDetails
          name={FINANCIAL_ADVISORS_INDUSTRY.name}
          body={FINANCIAL_ADVISORS_INDUSTRY.body}
          image={<StaticImage src="../images/financial-advising-industry.webp" alt={FINANCIAL_ADVISORS_INDUSTRY.name} width={66} />}
          onLearnMoreClick={() => handleLearnMoreClick(FINANCIAL_ADVISORS_INDUSTRY.modal.id)}
        />
        { selectedIndustry === FINANCIAL_ADVISORS_INDUSTRY.modal.id && (
          <IndustryModal
            modalId={FINANCIAL_ADVISORS_INDUSTRY.modal.id}
            heading={FINANCIAL_ADVISORS_INDUSTRY.name}
            body={FINANCIAL_ADVISORS_INDUSTRY.body}
            image={<StaticImage src="../images/financial-advising-industry.webp" alt={FINANCIAL_ADVISORS_INDUSTRY.name} width={66} />}
            valueProps={FINANCIAL_ADVISORS_INDUSTRY.modal.valueProps}
            onClose={handleCloseClick}
          />
        )}
        <IndustryDetails
          name={RETIREMENT_INDUSTRY.name}
          body={RETIREMENT_INDUSTRY.body}
          image={<StaticImage src="../images/retirement-planning-industry.webp" alt={RETIREMENT_INDUSTRY.name} width={66} />}
          onLearnMoreClick={() => handleLearnMoreClick(RETIREMENT_INDUSTRY.modal.id)}
        />
        { selectedIndustry === RETIREMENT_INDUSTRY.modal.id && (
          <IndustryModal
            modalId={RETIREMENT_INDUSTRY.modal.id}
            heading={RETIREMENT_INDUSTRY.name}
            body={RETIREMENT_INDUSTRY.body}
            image={<StaticImage src="../images/retirement-planning-industry.webp" alt={RETIREMENT_INDUSTRY.name} width={66} />}
            valueProps={RETIREMENT_INDUSTRY.modal.valueProps}
            onClose={handleCloseClick}
          />
        )}
      </IndustryCategories>
    </Wrapper>
  )
};
