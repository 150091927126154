import video from 'page-components/shared/videos/bottom-cta.mp4';
import React from 'react';
import styled from 'styled-components';

import { BottomCtaPattern2 } from 'page-components/shared/images/BottomCtaPattern2';
import { BottomCtaPattern3 } from 'page-components/shared/images/BottomCtaPattern3';

const StyledBottomCtaPattern2 = styled(BottomCtaPattern2)`
  position: absolute;
  bottom: 100%;
  left: 13%;
  width: 21%;
  height: 50%;
`;

const StyledBottomCtaPattern3 = styled(BottomCtaPattern3)`
  position: absolute;
  left: 54%;
  bottom: 0;
  width: 32%;
  height: 50%;
`;

const StyledVideo = styled.video`
  border-radius: 0 60px 0 0;
  width: 75%;
`;

const VideoWrapper = styled.div`
  position: relative;
  margin-top: 21.1%;
`;

export const VideoSmall = () => (
  <VideoWrapper>
    <StyledBottomCtaPattern2 />
    <StyledBottomCtaPattern3 />
  </VideoWrapper>
);
