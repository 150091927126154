import { GetStartedButton } from 'component-library/components/buttons/GetStartedButton';
import { Flex } from 'component-library/components/layout/Flex';
import { StaticImage } from 'gatsby-plugin-image';
import { WrapperLarge } from 'page-components/about-us/shared/WrapperLarge';
import React, { FC } from 'react';
import styled from 'styled-components';

import { RetirableLogoWithText } from 'component-library/components/icons';
import { Heading } from 'component-library/components/typography/Heading';
import { Subheading } from 'component-library/components/typography/Subheading';
import { navigateToPartnersSignUpForm } from 'page-components/partners/partners-sign-up-form';

const Text = styled(Flex)`
  flex-basis: 50%;
`;

const HeadingAndBody = styled.div`
  max-width: 790px;
  margin: 0 auto;
  padding: 12% 40px;
`;

const HeadingWrapper = styled(Flex)`
  flex-wrap: wrap;
  gap: 16px;
  align-items: baseline;
`;

const Image = styled(Flex)`
  flex-basis: 50%;
  margin: 32px 0;
`;

export const HeroLarge = () => (
  <WrapperLarge maxWidth={1728} spacing={0} padding="0">
    <Flex>
      <Text column>
        <HeadingAndBody>
          <HeadingWrapper>
            <Heading marginBottom={16} variant={3}>
              Partner with 
            </Heading>
            <StaticImage src="../../../partnerships/images/retirable-logo.webp" height={600} alt="hero" />
          </HeadingWrapper>
          <Flex column marginBottom={48} marginTop={72}>
            <Subheading variant={1}>Growth for your business.</Subheading>
            <Subheading variant={1}>A worry-free retirement for all.</Subheading>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <GetStartedButton
              buttonText="Book your demo"
              onClick={navigateToPartnersSignUpForm}
            />
          </Flex>
        </HeadingAndBody>
      </Text>
      <Image>
        <StaticImage src="../../images/biking-couple.webp" height={600} alt="hero" />
      </Image>
    </Flex>
  </WrapperLarge>
);
