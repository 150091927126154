import { GetStartedButton } from 'component-library/components/buttons/GetStartedButton';
import { Flex } from 'component-library/components/layout/Flex';
import { Modal, ModalBody } from 'component-library/components/modals/Modal';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Subheading } from 'component-library/components/typography/Subheading';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { navigateToPartnersSignUpForm } from '../partners-sign-up-form';

interface IndustryModalProps {
  modalId: string;
  heading: string;
  body: string;
  image: ReactNode;
  valueProps: any[];
  onClose: () => void;
}

const ModalSubHeading = styled(Body)`
  font-weight: bold;
  min-height: 60px;
`;

export const IndustryModal: FC<IndustryModalProps> = ({ modalId, heading, body, image, valueProps, onClose }) => (
  <Modal
    modalId={modalId}
    onClose={onClose}
    fullHeight={false}
    wide
  >
    <ModalBody>
      <Flex alignItems="center" gap={12}>
        {image}
        <Heading variant={5}>{heading}</Heading>
      </Flex>
      <Subheading variant={2} marginTop={16}>{body}</Subheading>
      <Flex gap={8} marginTop={24}>
        {valueProps.map((valueProp, index) => (
          <Flex key={index} column gap={16}>
            <ModalSubHeading key={index} variant={3}>{valueProp.headline}</ModalSubHeading>
            <Body variant={3}>{valueProp.description}</Body>
          </Flex>
        ))}
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" marginTop={32}>
        <GetStartedButton
          buttonText="Book your demo"
          onClick={() => { navigateToPartnersSignUpForm(); onClose(); }}
        />
      </Flex>
    </ModalBody>
  </Modal>
);
