import React, { FC } from 'react';
import styled from 'styled-components';
import { Flex, FlexProps } from '../layout/Flex';
import { colors } from 'component-library/styles/colors';
import { Subheading } from '../typography/Subheading';
import { Xmark } from '../icons';
import { PlusIcon } from '../icons/PlusIcon';

interface AccordionRowContentProps {
  title: string;
  content: string;
}

interface AccordionRowProps extends AccordionRowContentProps {
  isOpen: boolean;
  onToggle: () => void;
}

const AccordionRowWrapper = styled(Flex).attrs({
  column: true
})`
  border-bottom: 1px solid ${colors.grey};
  padding: 16px 0;
  cursor: pointer;
`;

const AccordionRow: FC<AccordionRowProps> = ({ title, content, isOpen, onToggle }) => (
  <AccordionRowWrapper onClick={onToggle}>
    <Flex justifyContent="space-between" alignItems="center">
      <Subheading variant={2} medium>{title}</Subheading>
      { isOpen ? <Xmark fill={colors.black} /> : <PlusIcon />}
    </Flex>
    { isOpen && <div>{content}</div> }
  </AccordionRowWrapper>
);

interface AccordionProps extends FlexProps {
  rows: AccordionRowContentProps[];
}
export const Accordion: FC<AccordionProps> = ({ rows, ...rest }) => {
  const [openRowIndex, setOpenRowIndex] = React.useState<number | null>(null);

  const handleRowClick = (index: number) => {
    index === openRowIndex ? setOpenRowIndex(null) : setOpenRowIndex(index);
  };

  return (
    <Flex column {...rest}>
      {rows.map((row, index) => (
        <AccordionRow
          key={index}
          {...row}
          isOpen={openRowIndex === index}
          onToggle={() => handleRowClick(index)}
        />
      ))}
    </Flex>
  );
}
