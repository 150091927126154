export const HEALTH_INDUSTRY = {
    name: 'Healthcare & Medicare Providers',
    body: 'Differentiate your Medicare offer while improving health and wealth outcomes.',
    image_src: '../images/healthcare-industry.webp',
    modal: {
        id: 'healthcareIndustryModal',
        valueProps: [
            {
                headline: 'Deepen client relationships',
                description: 'Provide your clients with a holistic health and wealth retirement solution with our complementary benefits.',
            },
            {
                headline: 'Boost annual renewals',
                description: 'We\'ll build AEP renewals into client financial plans so they know where to return ahead of each health care decision.',
            },
            {
                headline: 'Increase your revenue per client',
                description: 'You\'ll receive referral revenue for every qualified prospect you refer + recurring revenue for every client.',
            },
        ]
    }


};

export const INSURANCE_INDUSTRY = {
    name: 'Insurance & Annuity Providers',
    body: 'Complement your offerings with financial planning and investing solutions',
    modal: {
        id: 'insuranceIndustryModal',
        valueProps: [
            {
                headline: 'Expand your offering',
                description: 'Supplement your sales with financial plans and portfolios—without spending years getting licensed.',
            },
            {
                headline: 'Retain happily retired clients',
                description: 'Our solution helps clients navigate the transition from saving for retirement to spending in retirement.',
            },
            {
                headline: 'Keep dollars in your ecosystem',
                description: 'Retain more assets under management (AUM) with our platform purpose-built for modern retirement.',
            },
        ]
    }
};

export const HR_INDUSTRY = {
    name: 'HR & Benefit Administrators',
    body: 'Empower employees to transition into retirement with expert guidance',
    modal: {
        id: 'hrIndustryModal',
        valueProps: [
            {
                headline: 'Clarity for retirement',
                description: 'Our retirement peace of mind platform helps retirees plan, invest, spend, save and protect themselves in retirement.',
            },
            {
                headline: 'Extend relationships post-retirement',
                description: 'Our solution helps you off-board retiring employees while extending the relationship beyond the workplace.',
            },
            {
                headline: 'Personalized for every employee',
                description: 'We tailor our solution to each individual’s lifestyle and goals—all with the ongoing care of a fiduciary advisor.',
            },
        ]
    }
};

export const FINANCIAL_ADVISORS_INDUSTRY = {
    name: 'Financial Advisors',
    body: 'Succession planning for your aging clients with fiduciary support',
    modal: {
        id: 'financialAdvisorsIndustryModal',
        valueProps: [
            {
                headline: 'Ongoing care of fiduciary advisors',
                description: 'Give your business a legacy with ongoing care from true fiduciary Certified Financial Planners.',
            },
            {
                headline: 'Retirement specialists',
                description: 'Our solution delivers on each client’s goals while protecting their retirement income as they age.',
            },
            {
                headline: 'More than just investments',
                description: 'Retain more assets under management (AUM) with our platform purpose-built for modern retirement.',
            },
        ]
    }
};

export const RETIREMENT_INDUSTRY = {
    name: 'Retirement Plans',
    body: 'Transition client portfolios into retirement to navigate financial complexities',
    modal: {
        id: 'retirementIndustryModal',
        valueProps: [
            {
                headline: 'Improve profitability and retention',
                description: 'We specialize in transitioning retirees from accumulation to decumulation while lowering your cost to serve.',
            },
            {
                headline: 'Differentiate your value to retirees',
                description: 'We’ll help retirees plan, invest, spend, save and protect themselves in retirement so you don’t have to.',
            },
            {
                headline: 'Build engaging relationships',
                description: 'Our solution helps clients navigate the transition from saving for retirement to spending in retirement.',
            },
        ]
    }
};
