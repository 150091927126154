import { Flex } from 'component-library/components/layout/Flex';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

const FeaturedPartnersWrapper = styled(ShowOn)`
  border-bottom: 1px solid ${colors.navyBlue};
`;

const TrustedBy = styled(Heading)`
  padding: 32px;
  border-top: 1px solid ${colors.navyBlue};
  border-bottom: 1px solid ${colors.navyBlue};
  text-align: center;
`;

const Logos = styled(Flex)`
  flex-wrap: wrap;
  padding: 48px 32px;
  gap: 32px;
`;

export const FeaturedPartnersSmall = () => (
  <FeaturedPartnersWrapper screens={[Screen.Sm, Screen.Md, Screen.Lg]}>
    <TrustedBy variant={5}>Trusted By</TrustedBy>
    <Logos justifyContent="center">
      <StaticImage src="../images/ehealth-logo.webp" alt="eHealth" height={60} />
      <StaticImage src="../images/selectquote-logo.webp" alt="SelectQuote" height={60} />
      <StaticImage src="../images/spark-logo.webp" alt="Spark" height={80} />
      <StaticImage src="../images/the-brokerage-inc-logo.webp" alt="The Brokerage Inc" height={80} />
    </Logos>
  </FeaturedPartnersWrapper>
);
