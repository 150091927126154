import { GetStartedButton } from 'component-library/components/buttons/GetStartedButton';
import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

import { body, heading } from './content';
import { navigateToPartnersSignUpForm } from 'page-components/partners/partners-sign-up-form';
import { RetirableLogoWithText } from 'component-library/components/icons';
import { Subheading } from 'component-library/components/typography/Subheading';

const HeroWrapper = styled(ShowOn)`
  padding: 56px 0 12px;
`;

const Text = styled.div`
  padding: 0 20px;
`;

const HeadingWrapper = styled(Flex).attrs({ column: true })`
  align-items: baseline;
  margin-bottom: 32px;
`;

const ImageContainer = styled.div`
  margin: auto;
  max-width: 400px;
`;

export const HeroSmall = () => (
  <HeroWrapper screens={[Screen.Sm, Screen.Md]}>
    <Text>
      <HeadingWrapper>
        <Heading marginBottom={16} variant={4}>
          Partner with 
        </Heading>
        <RetirableLogoWithText />
      </HeadingWrapper>
      <Flex column marginBottom={48} marginTop={72}>
        <Subheading variant={2}>Growth for your business.</Subheading>
        <Subheading variant={2}>A worry-free retirement for all.</Subheading>
      </Flex>
      <Flex column alignItems="center" marginBottom={56}>
        <GetStartedButton
          buttonText="Book your demo"
          onClick={navigateToPartnersSignUpForm}
        />
      </Flex>
    </Text>
    <ImageContainer>
      <StaticImage src="../../images/biking-couple.webp" height={600} alt="hero" />
    </ImageContainer>
  </HeroWrapper>
);
